.dashboard-container {
    max-width: 100% !important;
}

.employer-dashboard-page,
.employee-dashboard-page {
    background-color: #F5F6F7;
    border-radius: 20px 20px 0 0;
    padding: 24px;
}

.employer-card,
.employee-card {
    width: 30%;
    /* border: 1px solid #ccc; */
    padding: 24px;
    background-color: white;
    border-radius: 16px;
}

.employers-section,
.employees-section {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.avatar-img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 100px !important;
    object-fit: cover;
    object-position: center;
}

.job-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 16px;
    flex-grow: 1;
}

.skill-icon {
    width: 24px !important;
    height: 24px !important;
    color: white !important;
}

.skill-icon-bg {
    background-color: #448BE6;
    width: fit-content;
    padding: 16px;
    border-radius: 16px;
}

.role-text {
    font-weight: 600 !important;
    font-size: 20px;
    text-align: left;
    margin: 16px 0;
}

.job-details-section {
    margin: 16px 0;
}

.job-details-section p {
    text-align: left;
}

.add-blog-section {
    display: flex;
    flex-direction: column;
}

.add-blog-section input:first-child {
    padding: 8px;
    margin-top: 16px;
}

.add-blog-section textarea {
    margin: 16px 0;
    width: 100% !important;
}

@media screen and (max-width: 768px) {

    .employers-section,
    .employees-section {
        flex-direction: column;
    }

    .employer-card,
    .employee-card {
        width: 100%;
    }

    .filter-section {
        justify-content: center !important;
    }

    .inputs-section input:last-child {
        margin-top: 16px;
    }
}

.sidebar-nav .nav-link.active {
    color: #1E1F25 !important;
    background-color: #F5F6F7 !important;
    border-radius: 12px 0 0 12px !important;
    align-items: center;
}

.nav-link.active .nav-link-curved {
    border-radius: 12px 0 0 12px !important;
}

.nav-link.active .nav-icon {
    color: #1e1f25 !important;
    height: inherit !important;
}

.nav-link-margin {
    margin-top: 16px !important;
}

.time-and-filters-section {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
}

.date-section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.date-section p {
    margin: 0 16px;
    cursor: pointer;
    color: '#1E1F25';
    opacity: 50%;
}

.date-section p:first-of-type {
    margin-left: 0px !important;
}

.date-section p.active {
    border-bottom: 1px solid #1E1F25;
    padding-bottom: 8px;
    font-weight: bold;
    color: #1E1F25;
    opacity: 1;
}

.filters-section {
    width: 50%;
    display: flex;
    justify-content: end;
}

.filters {
    display: flex;
    background-color: white;
    padding: 8px 24px;
    cursor: pointer;
    border-radius: 8px;
    color: #1E1F25;
    margin-right: 16px;
}

.filters i {
    font-size: 16px;
    margin-left: 8px;
    font-weight: bold;
}

.filters:last-of-type {
    margin-right: 0px !important;
}

.analytics-cards {
    margin: 32px 0;
    display: flex;
    justify-content: space-between;
}

.analytics-card {
    background-color: white;
    padding: 16px;
    width: 23%;
    border-radius: 8px;
    display: flex;
}

.icon-background {
    background: transparent linear-gradient(135deg, #CFE958 0%, #7FB35C 58%, #4C9060 100%) 0% 0% no-repeat padding-box;
    width: 40px;
    height: 40px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
}

.analytics-card:nth-child(2) .icon-background {
    background: linear-gradient(135deg, #FFED81 0%, #FFD868 23%, #FFA529 73%, #FF8804 100%) 0% 0% no-repeat padding-box;
}

.analytics-card:nth-child(3) .icon-background {
    background: transparent linear-gradient(135deg, #FFB18C 0%, #FF8461 33%, #FF1E00 100%) 0% 0% no-repeat padding-box;
}

.analytics-card:nth-child(4) .icon-background {
    background: transparent linear-gradient(135deg, #A1DDFF 0%, #1C83FF 100%) 0% 0% no-repeat padding-box;
}

.stat-and-trend-change {
    display: flex;
    align-items: baseline;
    padding-left: 16px;
    width: inherit;
    justify-content: space-between;
}

.stat-and-trend-change p {
    font-size: 24px;
    font-weight: bold;
    color: #1E1F25;
}

.stat-and-trend-change span {
    display: flex;
    margin-left: 16px;
}

.stat-and-trend-change span i {
    color: green;
    margin-right: 8px;
}

.analytics-card-icon {
    width: 40% !important;
}

.dashboard-analytics-section {
    display: block;
}

.dashboard-analytics-inner-container {
    display: flex;
    justify-content: space-between;
}

.dashboard-analytics-inner-container:nth-child(2) {
    margin: 32px 0;
}

.dashboard-analytics-card {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.dashboard-analytics-inner-container:nth-child(1) .dashboard-analytics-card:nth-child(1) {
    width: 59%;
}

.dashboard-analytics-inner-container:nth-child(1) .dashboard-analytics-card:nth-child(2) {
    width: 39%;
}

.dashboard-analytics-inner-container:nth-child(2) .dashboard-analytics-card {
    width: 32%;
}

.dashboard-analytics-card-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
}

.chart-area {
    /* margin-top: 16px; */
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.chart-options,
.chart-type,
.chart-time-period {
    display: flex;
}

.chart-options {
    width: 100%;
    justify-content: space-between;
    padding: 16px 0;
}

.chart-type p,
.chart-time-period p {
    cursor: pointer;
    opacity: 0.5;
    color: #1E1F25;
    transition: all 1s linear ease-in;
}

.chart-type p.active,
.chart-time-period p.active {
    opacity: 1;
    font-weight: bold;
}

.chart-type p:not(:first-child) {
    margin-left: 16px;
}

.chart-time-period p:not(:first-child) {
    margin-left: 16px;
}